:root {
  --swiper-theme-color: var(--color-blue-1);
  --swiper-navigation-size: 30px;
}
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

body {
  padding: 0;
  font-family: var(--font-primary);
  width: 100%;
  background-color: white;
  color: var(--color-dark-1);
  font-size: $font-size;
  line-height: 1.875;
}
.cursor-pointer {
  cursor: pointer;
}
.transition {
  transition: 0.3s;
}

main {
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  transition: color 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  font-size: $font-size;
  color: var(--color-light-1);
}
