.rate-input-container {
  @media (min-width: 768px) {
    max-width: calc((100% - 10px) / 2);
    min-width: calc((100% - 10px) / 2);
    width: calc((100% - 10px) / 2);
  }
  flex-grow: 1;
  flex-basis: 0;
}

.rate-container {
  width: 100%;
  @media (min-width: 768px) {
    max-width: calc((100% - 40px) / 3);
    min-width: calc((100% - 40px) / 3);
    width: calc((100% - 40px) / 3);
  }
}