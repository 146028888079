.rates-input-container {
  display: flex;
  flex-direction: row;
  @include media-below(md) {
    flex-direction: column;
  }
}

.rates-input-margin {
  width: 10px;
  @include media-below(md) {
    width: 0px;
  }
}

.rates-input-label {
  margin-bottom: 0px;
  font-size: .75rem;
}