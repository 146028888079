@import "slick-carousel/slick/slick.scss";
@import "slick-carousel/slick/slick-theme.scss";
// @import "../../node_modules/react-modal-video/scss/modal-video.scss";
@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";

@import "./abstracts/tools";
@import "./abstracts/variables";

@import "./animations/easings.scss";
@import "./animations/animations.scss";
@import "./animations/core.scss";

@import "./base/base";
@import "./base/button";
@import "./base/form";
@import "./base/typography";

@import "./components/404.scss";
@import "./components/sectionTitle.scss";
@import "./components/switch.scss";
@import "./components/ratesInput.scss";
@import "./components/datetimePicker.scss";
@import "./components/masthead.scss";
@import "./components/citiesCard.scss";
@import "./components/ctaCard.scss";
@import "./components/tabs.scss";
@import "./components/accordion.scss";
@import "./components/header.scss";
@import "./components/content.scss";
@import "./components/cardImage.scss";
@import "./components/footer.scss";
@import "./components/mainSearch.scss";
@import "./components/featureIcon.scss";
@import "./components/testimonials.scss";
@import "./components/pagination.scss";
@import "./components/dashboard.scss";
@import "./components/blogCard.scss";
@import "./components/dragSlider.scss";
@import "./components/errorText.scss";
@import "./components/niceSelect.scss";
@import "./components/mapFilter.scss";
@import "./components/hotelsCard.scss";
@import "./components//rates.scss";

@import "./components/preloader.scss";

@import "./utilities/spacing";
@import "./utilities/utilities";

@import "./vendors/icons.scss";

// custom scss for all site & slick
@import "./custom/custom-slick";
@import "./custom/price-range-slider";
@import "./custom/custom-next";

.collapse.show {
  visibility: visible;
}