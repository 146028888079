@font-face {
  font-family: "icomoon";
  src: url("../../fonts/icomoon.eot?ibiouq");
  src: url("../../fonts/icomoon.eot?ibiouq#iefix") format("embedded-opentype"),
    url("../../fonts/icomoon.ttf?ibiouq") format("truetype"), url("../../fonts/icomoon.woff?ibiouq") format("woff"),
    url("../../fonts/icomoon.svg?ibiouq#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu-2:before {
  content: "\e95e";
}
.icon-play:before {
  content: "\e95d";
}
.icon-trash:before {
  content: "\e95b";
}
.icon-day-night:before {
  content: "\e95c";
}
.icon-share:before {
  content: "\e951";
}
.icon-clock:before {
  content: "\e952";
}
.icon-customer:before {
  content: "\e953";
}
.icon-route:before {
  content: "\e954";
}
.icon-access-denied:before {
  content: "\e955";
}
.icon-compass:before {
  content: "\e956";
}
.icon-calendar-2:before {
  content: "\e957";
}
.icon-placeholder:before {
  content: "\e958";
}
.icon-group:before {
  content: "\e959";
}
.icon-calendar:before {
  content: "\e95a";
}
.icon-location-pin:before {
  content: "\e946";
}
.icon-nearby:before {
  content: "\e947";
}
.icon-plans:before {
  content: "\e948";
}
.icon-price-label:before {
  content: "\e949";
}
.icon-ticket:before {
  content: "\e94a";
}
.icon-food:before {
  content: "\e94d";
}
.icon-dislike:before {
  content: "\e94e";
}
.icon-like:before {
  content: "\e94f";
}
.icon-airplane:before {
  content: "\e950";
}
.icon-living-room:before {
  content: "\e945";
}
.icon-location-2:before {
  content: "\e941";
}
.icon-pedestrian:before {
  content: "\e942";
}
.icon-award:before {
  content: "\e943";
}
.icon-location:before {
  content: "\e944";
}
.icon-up-down:before {
  content: "\e940";
}
.icon-newsletter:before {
  content: "\e93f";
}
.icon-close:before {
  content: "\e93e";
}
.icon-kitchen:before {
  content: "\e939";
}
.icon-man:before {
  content: "\e93a";
}
.icon-no-smoke:before {
  content: "\e93b";
}
.icon-parking:before {
  content: "\e93c";
}
.icon-wifi:before {
  content: "\e93d";
}
.icon-city-2:before {
  content: "\e931";
}
.icon-tv:before {
  content: "\e932";
}
.icon-washing-machine:before {
  content: "\e933";
}
.icon-juice:before {
  content: "\e934";
}
.icon-shield:before {
  content: "\e935";
}
.icon-bell-ring:before {
  content: "\e936";
}
.icon-bathtub:before {
  content: "\e937";
}
.icon-check:before {
  content: "\e938";
}
.icon-arrow-right:before {
  content: "\e930";
}
.icon-edit:before {
  content: "\e92c";
}
.icon-eye:before {
  content: "\e92d";
}
.icon-trash-2:before {
  content: "\e92e";
}
.icon-upload-file:before {
  content: "\e92f";
}
.icon-email-2:before {
  content: "\e94b";
}
.icon-notification:before {
  content: "\e94c";
}
.icon-arrow-left:before {
  content: "\e92b";
}
.icon-arrow-sm-left:before {
  content: "\e929";
}
.icon-arrow-sm-right:before {
  content: "\e92a";
}
.icon-fire:before {
  content: "\e900";
}
.icon-globe:before {
  content: "\e901";
}
.icon-heart:before {
  content: "\e902";
}
.icon-hiking:before {
  content: "\e903";
}
.icon-hiking-2:before {
  content: "\e904";
}
.icon-home:before {
  content: "\e905";
}
.icon-instagram:before {
  content: "\e906";
}
.icon-jeep:before {
  content: "\e907";
}
.icon-linkedin:before {
  content: "\e908";
}
.icon-luggage:before {
  content: "\e909";
}
.icon-menu:before {
  content: "\e90a";
}
.icon-museum:before {
  content: "\e90b";
}
.icon-nature:before {
  content: "\e90c";
}
.icon-play-market:before {
  content: "\e90d";
}
.icon-search:before {
  content: "\e90e";
}
.icon-ski:before {
  content: "\e90f";
}
.icon-speedometer:before {
  content: "\e910";
}
.icon-star:before {
  content: "\e911";
}
.icon-tickets:before {
  content: "\e912";
}
.icon-transmission:before {
  content: "\e913";
}
.icon-traveller:before {
  content: "\e914";
}
.icon-twitter:before {
  content: "\e915";
}
.icon-usd:before {
  content: "\e916";
}
.icon-user:before {
  content: "\e917";
}
.icon-user-2:before {
  content: "\e918";
}
.icon-yatch:before {
  content: "\e919";
}
.icon-minus:before {
  content: "\e91a";
}
.icon-plus:before {
  content: "\e91b";
}
.icon-apple:before {
  content: "\e91c";
}
.icon-chevron-sm-down:before {
  content: "\e91d";
}
.icon-arrow-top-right:before {
  content: "\e91e";
}
.icon-beach-umbrella:before {
  content: "\e91f";
}
.icon-bed:before {
  content: "\e920";
}
.icon-camping:before {
  content: "\e921";
}
.icon-car:before {
  content: "\e922";
}
.icon-chevron-left:before {
  content: "\e923";
}
.icon-chevron-right:before {
  content: "\e924";
}
.icon-city:before {
  content: "\e925";
}
.icon-destination:before {
  content: "\e926";
}
.icon-email:before {
  content: "\e927";
}
.icon-facebook:before {
  content: "\e928";
}
.icon-verified:before {
  content: "\f336";
}