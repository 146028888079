.datetimepicker {
  border: 1px solid var(--color-border) !important;
  border-radius: 4px !important;
  padding: 0.55rem 2.25rem 0.55rem 0.75rem !important;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
  width: 100% !important;
}

.datetimepicker_wrapper {
  width: 100%;
}